import axios from 'axios';

const http = axios.create({
  baseURL: $config.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.defaults.timeout = 10000;
http.defaults.params = {};

http.interceptors.request.use(
  reqConfig => {
    reqConfig.params = {
      ...reqConfig.params,
      ...window.shopifyData,
    };
    return reqConfig;
  },
  err => Promise.reject(err),
);

http.interceptors.response.use(
  ({ data }) => data,
  ({ response }) => Promise.reject(response),
);

window.$http = http;

export default http;
