import Vue from 'vue';
import VuePerfectScrollbar from 'vue2-perfect-scrollbar';
import queryString from 'query-string';

import './config';
import './filters';
import './services/ApiService';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import AppMixin from './mixins/AppMixin';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

window.shopifyData = queryString.parse(window.location.search);
// window.shopifyData = { store: 'http://localhost:8888' };

Vue.config.productionTip = false;

Vue.use(VuePerfectScrollbar);

Vue.mixin(AppMixin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
